var columns = [{
  title: '获奖ID',
  dataIndex: 'activity_id',
  key: 'activity_id',
  width: '10%',
  scopedSlots: {
    customRender: 'activity_id'
  }
}, {
  title: '奖项名称',
  dataIndex: 'name',
  key: 'name',
  width: '19%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '奖项图片',
  dataIndex: 'img',
  key: 'img',
  width: '10%',
  scopedSlots: {
    customRender: 'img'
  }
}, {
  title: '获奖人邮箱',
  dataIndex: 'email',
  key: 'email',
  width: '13%',
  scopedSlots: {
    customRender: 'email'
  }
}, {
  title: '获奖人昵称',
  dataIndex: 'user_name',
  key: 'user_name',
  width: '11%',
  scopedSlots: {
    customRender: 'user_name'
  }
}, {
  title: '获奖时间',
  dataIndex: 'winning_time',
  key: 'winning_time',
  width: '17%',
  scopedSlots: {
    customRender: 'winning_time'
  }
}, {
  title: '备注',
  dataIndex: 'note',
  key: 'note',
  width: '9%',
  scopedSlots: {
    customRender: 'note'
  }
  //   ellipsis: true,
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 100,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };